import React from 'react'

const Gallery = () => {
    return (
        <>

            <div>
                <section className="inner-banner">
                    <h1 className="font-weight-bold text-center">Gallery</h1>
                </section>
                <section className="course">
                    <div className="container">
                        <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                            <span className="title">Our Gallery</span>
                            <h2>Life At Campus</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3 p-0 justify-content-center" id="pills-tab" role="tablist" data-aos="fade-up" data-aos-duration={1000}>
                            <li className="nav-item">
                                <a className="nav-link active" id="showall-tab" data-bs-toggle="pill" href="#showall" role="tab" aria-controls="showall" aria-selected="true">Show All</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="Events-tab" data-bs-toggle="pill" href="#Events" role="tab" aria-selected="false">Events</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="Sports-tab" data-bs-toggle="pill" href="#Sports" role="tab" aria-selected="false">Sports</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" id="Classroom-tab" data-bs-toggle="pill" href="#Classroom" role="tab" aria-selected="false">Classroom</a>
                            </li> */}
                        </ul>
                        <div className="row">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane  fade show active" id="showall" role="tabpanel" aria-labelledby="showall-tab">
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/47.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/47.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/48.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/48.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/70.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/70.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/76.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/76.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/09.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/09.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/11.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/11.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/12.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/12.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/13.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/13.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/14.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/14.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/15.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/15.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>

                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/5.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/5.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/7.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/7.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/8.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/8.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/17.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/17.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/30.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/30.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/31.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/31.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/116.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/116.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/01.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/01.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/03.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/04.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/05.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/05.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/06.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/06.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/07.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/07.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/08.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/08.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/116.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/116.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>

                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/1.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/1.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/2.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/2.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/3.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/3.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/4.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/4.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/5.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/5.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/6.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/6.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>


                                </div>
                                <div className="tab-pane  fade" id="Events" role="tabpanel" aria-labelledby="Events-tab">
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/47.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/47.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/48.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/48.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/70.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/70.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/76.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/76.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/09.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/09.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/11.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/11.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/12.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/12.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/13.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/13.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/14.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/14.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/15.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/15.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>




                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/5.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/5.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid " src="assets/images/school/events/events/events/7.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/7.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="img-fluid" src="assets/images/school/events/events/events/8.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/8.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/17.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/17.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/30.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/30.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/31.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/31.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/116.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/116.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/01.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/01.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/03.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/04.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/05.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/05.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/06.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/06.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/07.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/07.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/events/08.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/08.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/events/116.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/events/116.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Event</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* Gallery sport */}

                                <div className="tab-pane fade" id="Sports" role="tabpanel" aria-labelledby="Sports-tab">
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/1.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/1.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/2.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/2.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/3.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/3.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/4.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/4.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/5.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/5.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/6.JPEG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/6.JPEG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Sports</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade" id="Classroom" role="tabpanel" aria-labelledby="Classroom-tab">
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/114.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/1.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Classroom</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/114.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/114.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Classroom</h5>
                                        </div>
                                    </div>
                                    <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                        <img className="card-img" src="assets/images/school/events/Sports/Sports/114.JPG" alt="Gallery" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/school/events/Sports/114.JPG" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="font-weight-bold">Classroom</h5>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Gallery