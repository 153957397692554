import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Gallery from "./pages/gallery/Gallery";
import Contact from "./pages/contact/Contact";
import Blog from "./pages/blog/Blog";
import DirectorMessage from "./pages/about/DirectorMessage";
import AboutUs from "./pages/about/AboutUs";
import PrincipalsMessage from "./pages/about/PrincipalsMessage";
import VisionAndMission from "./pages/about/VisionAndMission";
import Facility from "./pages/facility/Facility";
import Admission from "./pages/admission/Admission";
import TopResult from "./pages/result/TopResult";
import Activities from "./pages/activities/Activities";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/PrincipalsMessage" element={<PrincipalsMessage />} />
            <Route path="/DirectorMessage" element={<DirectorMessage />} />
            <Route path="/VisionAndMission" element={<VisionAndMission />} />
            <Route path="/Facility" element={<Facility />} />
            <Route path="/Activities" element={<Activities />} />
            <Route path="/Admission" element={<Admission />} />
            <Route path="/TopResult" element={<TopResult />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Contact" element={<Contact />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
