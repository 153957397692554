import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';


import 'swiper/css';



import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="">
                        <img src="assets/images/school/banner_5.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInLeft delay-0.5s">
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/images/school/banner_3.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInLeft delay-0.5s">
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/images/school/banner_2.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInUp delay-0.5s">

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/images/school/banner_1.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInUp delay-0.5s">

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/images/school/banner_4.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInUp delay-0.5s">

                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
