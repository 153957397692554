import React from 'react'
import SliderTopResult from '../../component/slidercomponent/SliderTopResult'

const TopResult = () => {
    return (
        <>

            <div>
                <section className="inner-banner">
                    <h1 className="fw-bold text-center">Top Result</h1>
                </section>
                <section className="result">
                    <div className="container">
                        <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                            <h2>Top Result</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
            <section className="result-box" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="table-responsive">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Student Name</th>
                                                <th scope="col">Class</th>
                                                <th scope="col">Percentage</th>
                                                <th scope="col">Session</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Prakash Soni (Maths)</td>
                                                <td>12th</td>
                                                <td>93%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Kanhaiya Gupta (Comm.)</td>
                                                <td>12th</td>
                                                <td>87.2%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Vivek Sondhiya (Bio)</td>
                                                <td>12th</td>
                                                <td> 85%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td> Shivendra Singh (Bio)</td>
                                                <td>12th</td>
                                                <td>84.6%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            
                                            <tr>
                                                <td> Arin Tripathi (Maths)</td>
                                                <td>12th</td>
                                                <td> 84.4%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td> Shreya Shukla (Bio)</td>
                                                <td>12th</td>
                                                <td>84.4%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Arshita Singh</td>
                                                <td>10th</td>
                                                <td>95.4%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Ravi Kushwaha</td>
                                                <td>10th</td>
                                                <td>88.4%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Abhay Pathak</td>
                                                <td>10th</td>
                                                <td>88.2%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Krishna Mishra</td>
                                                <td>10th</td>
                                                <td>87.4%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Sakshi Kushwaha</td>
                                                <td>10th</td>
                                                <td>85.8%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Harshita</td>
                                                <td>8th</td>
                                                <td>90%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Ankita</td>
                                                <td>8th</td>
                                                <td>89.9%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Arman</td>
                                                <td>8th</td>
                                                <td>89.7%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Khushi</td>
                                                <td>8th</td>
                                                <td>89.7%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Anushka</td>
                                                <td>8th</td>
                                                <td>88.8%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Aryan</td>
                                                <td>5th</td>
                                                <td>91.1%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Janvi</td>
                                                <td>5th</td>
                                                <td>90%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Satyam</td>
                                                <td>5th</td>
                                                <td>90%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Tripti</td>
                                                <td>5th</td>
                                                <td>89.3%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td>Ruchi</td>
                                                <td>5th</td>
                                                <td>89%</td>
                                                <td>2023-24</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="result-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="sec-title mb-3" data-aos="fade-up" data-aos-duration={1000}>
                                    <span className="title">Result</span>
                                    <h2>Top Result</h2>
                                    <div className="divider">
                                        <span className="fa fa-mortar-board" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="autoplay">
                                    <SliderTopResult />
                                    {/* <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_1.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_1.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div>
                                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_2.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_2.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div>
                                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_3.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_3.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div>
                                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_4.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_4.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div>
                                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_4.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_4.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div>
                                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                                        <img src="assets/images/course_5.png" className="img-fluid" alt="Result" />
                                        <div className="overlay" />
                                        <div className="overlay-text">
                                            <a href="assets/images/course_5.png" data-fancybox="gallery">
                                                <i className="fa fa-plus fa-2x" />
                                            </a>
                                            <h5 className="fw-bold">Result</h5>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default TopResult