import React, { useState } from 'react';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';


const Hadear1 = () => {
    const [ismobile, setIsmobile] = useState(0);
    return (
        <>
            <header className="top-header py-2">
                <div className="container">
                    <div className="row align-content-center">
                        <div className="col-xl-3 col-lg-4 col-md-12 text-start top-btn">
                            <Link to="/Admission">
                                <button type="button" className="btn theme-orange">Admission & Fees</button></Link>
                            <Link to="/Contact">
                                <button type="button" className="btn theme-orange">Quick Inquery</button></Link>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 py-2 fw-bold f-15 announcement">
                            <i className="fa fa-bullhorn me-3" />
                            <span>Announcement</span>
                        </div>
                        <div className="col-xl-7 col-lg-5 col-md-8">
                            <p className="marquee">
                                <span> Admission Open for New Session 2024-25 in Sudarshan Public Hr. Sec. School with the best facilities.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <nav className="navbar navbar-expand-lg sticky-top">
                <div className="container">
                    <Link className="navbar-brand">
                        <div className="logo-wrapper">
                            <Link className='d-flex justify-content-center align-items-center' to="/">
                                <img src="assets/images/school/events/logo-1.png" className="img-fluid" alt="Edusquad" />
                            </Link>
                        </div>
                    </Link>
                    <div className={ismobile ? "moblink" : "desklink"} onClick={() => setIsmobile(false)}>
                        <ul>
                            <Link className=" text-decoration-none" to="/">
                                <li >Home</li>
                            </Link>
                            <Link className=" text-decoration-none" to="/AboutUs">
                                <li className='li' >About Us</li>
                            </Link>
                            <Link className=" text-decoration-none" to="/DirectorMessage">
                                <li className='li' >Director Message</li>
                            </Link>
                            <li>
                                <Link className=" text-decoration-none" to="/Facility">
                                    <li> Facility </li>
                                </Link>
                            </li>
                            <li>
                                <Link className=" text-decoration-none" to="/Activities">
                                    <li> Activities </li>
                                </Link>
                            </li>

                            <li>
                                <Link className=" text-decoration-none" to="/Admission">
                                    <li> Admission</li>
                                </Link>
                            </li>
                            <li>
                                <Link className=" text-decoration-none" to="/TopResult">
                                    <li> Top Result</li>
                                </Link>
                            </li>
                            <li>
                                <Link className=" text-decoration-none" to="/Gallery">
                                    <li> Gallery</li>
                                </Link>
                            </li>
                            <li>
                                <Link className=" text-decoration-none" to="/Blog">
                                    <li> Blog</li>
                                </Link>
                            </li>
                            <li>
                                <Link className=" text-decoration-none" to="/Contact">
                                    <li> Contact</li>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    <button className='vavbutn' onClick={() => setIsmobile(!ismobile)} style={{marginBottom:"60px"}}>
                        {ismobile ? < IoMdClose /> : < GiHamburgerMenu />}
                    </button>
                </div>
            </nav>
        </>
    )
}
export default Hadear1