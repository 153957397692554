import React from 'react'
import { Link } from "react-router-dom";
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner';


const HomeBanner = () => {
    return (
        <>
            <div className="page-wrapper" id="page-wrapper">
                <div id="demo" className="carousel slide" data-bs-ride="carousel">
                    <ul className="carousel-indicators">
                        {/* <li data-bs-target="#demo" data-bs-slide-to={0} className="active" />
                        <li data-bs-target="#demo" data-bs-slide-to={1} />
                        <li data-bs-target="#demo" data-bs-slide-to={2} />
                        <li data-bs-target="#demo" data-bs-slide-to={3} /> */}
                    </ul>   
                    <div className="carousel-inner">
                    <SliderHomeBanner />
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeBanner