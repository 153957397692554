import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderHomeReviews() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <div className="center">
                    <SwiperSlide>
                        <div className="card m-2">
                            <div className="client-block p-3" data-aos="zoom-in" data-aos-duration={1000}>
                                <div className="media p-3 align-items-center">
                                    <img src="assets/images/school/profile.png" alt="Rony Devis" className="me-3 rounded-circle" />

                                </div>
                                <p className="ps-3 pe-3 text-start" >It is a place where students can study with dedication and without any distraction. The school is having high quality infrastructure in order to render quality education. Apart from this, the school also focusses on all round development of the children by involving them in indoor and outdoor games. </p>
                                <div className="media-body text-start">
                                    <h6 className="color-orange fw-bold mb-0" style={{ textAlign: "center" }}>Mr. Yogesh kushvaha</h6>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card m-2">
                            <div className="client-block p-3" data-aos="zoom-in" data-aos-duration={1000}>
                                <div className="media p-3 align-items-center">
                                    <img src="assets/images/school/profile.png" alt="Rony Devis" className="me-3 rounded-circle" />

                                </div>
                                <p className="ps-3 pe-3 text-start" style={{ textAlign: "justify" }}>This place is very close to the nature and very peaceful. If you see this place by your eyes, you ll gonna be fan of the campus and greenery. The infrastructure, gardening everything is incredible. Also there is more facilities are provided like a huge cricket ground, badminton and volleyball courts.</p>
                                <div className="media-body text-start">
                                    <h6 className="color-orange fw-bold mb-0" style={{ textAlign: "center" }}>Muskan Tripathi</h6>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card m-2">
                            <div className="client-block p-3" data-aos="zoom-in" data-aos-duration={1000}>
                                <div className="media p-3 align-items-center">
                                    <img src="assets/images/school/profile.png" alt="Rony Devis" className="me-3 rounded-circle" />

                                </div>
                                <p className="ps-3 pe-3 text-start" style={{ textAlign: "justify" }}>One of the best school for kids in Satna Such a beautiful and fresh new enviroment for kids growth. There is nothing like rattafication method  , they focus on concept understanding. Teachers are very hard working. </p>
                                <br />
                                <div className="media-body text-start">
                                    <h6 className="color-orange fw-bold mb-0" style={{ textAlign: "center" }}>Deepmala Singh</h6>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="card m-2">
                            <div className="client-block p-3" data-aos="zoom-in" data-aos-duration={1000}>
                                <div className="media p-3 align-items-center">
                                    <img src="assets/images/school/profile.png" alt="Rony Devis" className="me-3 rounded-circle" />

                                </div>
                                <p className="ps-3 pe-3 text-start">It is very good school for budding talent,The best thing is it focus on all-round development of students.</p><br /><br /><br /><br />
                                <div className="media-body text-start">
                                    <h6 className="color-orange fw-bold mb-0" style={{ textAlign: "center" }}>Jyoti Singh</h6>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </div>
            </Swiper>
        </>
    );
}
