import React from 'react'
import HomeBanner from './home/HomeBanner'
import Opportunities from './home/Opportunities'
import MissionVision from './home/MissionVision'
import HomeAbout from './home/HomeAbout'
import HomeReviews from './home/HomeReviews'
import HomeGallery from './home/HomeGallery'
import HomeBlog from './home/HomeBlog'
import OurTeam from './home/OurTeam'
import HomeTopResult from './home/HomeTopResult'

const Home = () => {
  return (
    <>

        <HomeBanner />
        <Opportunities />
        <MissionVision />
        <HomeAbout />
        <OurTeam />
        <HomeReviews />
        <HomeGallery />
        <HomeBlog />
        <HomeTopResult />

      

      
    </>
  )
}

export default Home