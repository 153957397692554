import React from 'react'

const MissionVision = () => {
    return (
        <>
            <section className="target">
                <div className="container">
                    <div className="sec-title text-center bg-fill mb-3">
                        <span className="title">Mission & Vision</span>
                        {/* <h2 className="text-white">Ethics Behind Success</h2> */}
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="target-block text-white p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-users fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Mission</h4>
                                <p style={{textAlign:"justify"}}>We nurture and nourish the innate potential of each child, by providing the rich source of academics as well as other co-curricular activities. We always strive for the overall personality development of the child.</p>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white  p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-mortar-board fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Vision</h4>
                                <p style={{textAlign:"justify"}}>Our vision is to develop an inspiring community with high critical thinkers, achievers, and compassionate human beings with progressive and positive development.</p>
                                <br/>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white p-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-book fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Core Beliefs</h4>
                                <p style={{textAlign:"justify"}}>We strongly believe that each child has a unique talent and it’s our responsibility to find and develop the innate talent of the child. Our core elements are well-equipped infrastructure, innovative teaching methodology, and the best faculty.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default MissionVision