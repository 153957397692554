import React from 'react'
import MissionVision from '../home/MissionVision'
import Opportunities from '../home/Opportunities'
import HomeReviews from '../home/HomeReviews'

const VisionAndMission = () => {
    return (
        <>
            <section className="inner-banner">
                <h1 className="font-weight-bold text-center">Vision And Mission</h1>
            </section>
            <Opportunities />
            <MissionVision />
            <HomeReviews />


        </>
    )
}

export default VisionAndMission