import React from 'react'

const Opportunities = () => {
    return (
        <>
            <section className="counter-section">
                <div className="container">
                    <div className="counter-box">
                        <div class="sec-title text-center mb-3">
                            <h2>Avenue of Opportunities</h2>
                            <div class="divider">
                                <span class="fa fa-mortar-board"></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter" data-aos="fade-up" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <div className="counter-img">
                                        <img src="assets/images/icons/teacher.png" className="img-fluid" alt="teacher" />
                                    </div>
                                    <h5 className="count-text mt-2">Teachers</h5>
                                    <h2 className="timer count-title count-number" data-to={55} data-speed={1500}>55+ </h2>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3" data-aos="fade-up" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <div className="counter-img">
                                        <img src="assets/images/icons/classroom.png" className="img-fluid" alt="classroom" />
                                    </div>
                                    <h5 className="count-text mt-2">Classroom</h5>
                                    <h2 className="timer count-title count-number" data-to={24} data-speed={1500}>24+</h2>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter mt-xl-0 mt-lg-0 mt-md-4 mt-sm-3 mt-3" data-aos="fade-up" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <div className="counter-img">
                                        <img src="assets/images/icons/career.png" className="img-fluid" alt="career" />
                                    </div>
                                    <h5 className="count-text mt-2">Students</h5>
                                    <h2 className="timer count-title count-number" data-to={955} data-speed={1500}>955+</h2>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="counter mt-xl-0 mt-lg-0 mt-md-4 mt-sm-3 mt-3" data-aos="fade-up" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <div className="counter-img">
                                        <img src="assets/images/icons/activity.png" className="img-fluid" alt="activity" />
                                    </div>
                                    <h5 className="count-text mt-2">Award</h5>
                                    <h2 className="timer count-title count-number" data-to={20} data-speed={1500}>20+</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Opportunities