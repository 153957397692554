import React from 'react'
import SliderHomeReviews from '../../component/slidercomponent/SliderHomeReviews'

const HomeReviews = () => {
    return (
        <>

            <section className="events text-center">
                <div className="container-fluid">
                    <div className="sec-title text-center" >
                        <h2>Reviews !</h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <SliderHomeReviews />
                   
                </div>
            </section>

        </>
    )
}

export default HomeReviews