import React from 'react'

const OurTeam = () => {
    return (
        <>
            <section className="our-team text-center">
                {/* <section className="our-team text-center" style={{backgroundColor:"#dde7eb"}}> */}
                <div className="container">
                    <div className="sec-title text-center mb-3">
                        <div className="sec-title text-center" >
                            <h2>Our Team </h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>

                        <div className="">
                            <img src="assets/images/DSC_7040.webp" className="img-fluid" alt="team member" />
                        </div>
                        {/* <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div> */}
                    </div>
                    {/* <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" >
                            <div className="team-block">
                                <div className="team-img">
                                    <img src="assets/images/team_2.png" className="img-fluid" alt="team member" />
                                </div>
                                <ul className="social-connect ps-0">
                                    <li>
                                    <i class="fa fa-facebook"></i>
                                    </li>
                                    <li>
                                        <i className="fa fa-twitter" />
                                    </li>
                                    <li>
                                        <i className="fa fa-youtube" />
                                    </li>
                                    <li>
                                        <i className="fa fa-instagram" />
                                    </li>
                                </ul>
                                <h4 className="pt-4 fw-bold">Sarry Denia</h4>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" >
                            <div className="team-block mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2">
                                <div className="team-img">
                                    <img src="assets/images/team_1.png" className="img-fluid" alt="team member" />
                                </div>
                                <ul className="social-connect ps-0">
                                    <li>
                                        <i className="fa fa-facebook" />
                                    </li>
                                    <li>
                                        <i className="fa fa-twitter" />
                                    </li>
                                    <li>
                                        <i className="fa fa-youtube" />
                                    </li>
                                    <li>
                                        <i className="fa fa-instagram" />
                                    </li>
                                </ul>
                                <h4 className="pt-4 fw-bold">Kevin Walker</h4>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" >
                            <div className="team-block  mt-xl-0 mt-lg-0 mt-mb-2 mt-sm-2 mt-2">
                                <div className="team-img">
                                    <img src="assets/images/team_3.png" className="img-fluid" alt="team member" />
                                </div>
                                <ul className="social-connect ps-0">
                                    <li>
                                        <i className="fa fa-facebook" />
                                    </li>
                                    <li>
                                        <i className="fa fa-twitter" />
                                    </li>
                                    <li>
                                        <i className="fa fa-youtube" />
                                    </li>
                                    <li>
                                        <i className="fa fa-instagram" />
                                    </li>
                                </ul>
                                <h4 className="pt-4 fw-bold">Marry Scott</h4>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" >
                            <div className="team-block">
                                <div className="team-img mt-xl-0 mt-lg-0 mt-mb-2 mt-sm-2 mt-2">
                                    <img src="assets/images/team_4.png" className="img-fluid" alt="team member" />
                                </div>
                                <ul className="social-connect ps-0">
                                    <li>
                                        <i className="fa fa-facebook" />
                                    </li>
                                    <li>
                                        <i className="fa fa-twitter" />
                                    </li>
                                    <li>
                                        <i className="fa fa-youtube" />
                                    </li>
                                    <li>
                                        <i className="fa fa-instagram" />
                                    </li>
                                </ul>
                                <h4 className="pt-4 fw-bold">Lili Jameson</h4>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>


        </>
    )
}

export default OurTeam