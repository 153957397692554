import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTopResult() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/1.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/1.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/2.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/2.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/3.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/3.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/4.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/4.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/5.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/5.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/6.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/6.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/7.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/7.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/8.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/8.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/9.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/9.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/10.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/10.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/11.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/11.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/12.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/12.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/13.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/13.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/14.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/14.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/15.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/15.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/16.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/17.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/18.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/18.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/19.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/19.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/20.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/20.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/images/school/21.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/images/school/21.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>
                            <h5 className="fw-bold">Result</h5>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
