import React from 'react'
import HomeBlog from '../home/HomeBlog'

const Blog = () => {
    return (
        <>

            <section className="inner-banner">
                <h1 className="fw-bold text-center">Blog</h1>
            </section>
        <HomeBlog />
            

        </>
    )
}

export default Blog