import React from 'react'
import { Link } from "react-router-dom";


const HomeGallery = () => {
    return (
        <>
            <section className="blog">
                <div className="container">
                    <div className="sec-title text-center mb-3">
                        {/* <span className="title">BLOG &amp; NEWS</span> */}
                        <h2>School Gallery </h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/Gallery">
                                <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                    <img src="assets/images/school/events/58.JPG" className="img-fluid" alt="Blog" />
                                </div></Link>
                        </div>
                        <div className="col-md-4">
                            <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/school/events/112.JPG" className="img-fluid" alt="Blog" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Link to="/Gallery">
                                <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                    <img src="assets/images/school/events/69.JPG" className="img-fluid" alt="Blog" />
                                </div></Link>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/Gallery">
                                <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                    <img src="assets/images/school/events/17.JPG" className="img-fluid" alt="Blog" />

                                </div></Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/Gallery">
                                <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                    <img src="assets/images/school/events/113.JPG" className="img-fluid" alt="Blog" />
                                </div></Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/Gallery">
                                <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                    <img src="assets/images/school/events/1.JPG" className="img-fluid" alt="Blog" />
                                </div></Link>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeGallery