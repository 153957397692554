import React from 'react'
import HomeReviews from '../home/HomeReviews'

const Facility = () => {
    return (
        <>
            <section className="inner-banner">
                <h1 className="font-weight-bold text-center">Facility</h1>
            </section>


            <section className="achievement">
                <div className="container">
                    <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                        <h2>Our Facility</h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Computer Lab</h3>
                            <p style={{ textAlign: "justify" }}>It also has a well developed computer laboratory for the pupils from Class V to Class XII. Internet facility is also available to the senior students. The computer Laboratory has been extended and now we have a separate computer laboratory for the the Senior Secondary Students.</p>
                            <p style={{ textAlign: "justify" }}>.</p>
                            <p style={{ textAlign: "justify" }}>.</p>


                        </div>
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Computer Lab.webp" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block left-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/School Bus Transportation.webp" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-xl-5 mt-lg-5 mt-md-2 mt-sm-5 mt-5">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Transport</h3>
                            <p style={{ textAlign: "justify" }}>Bus facilities are available to Day-scholars only. This facility may not be withdrawn in between the session. Parents are requested to send their children bus-stop at least 10 minutes before in the morning and collect them from their same stoppage in the afternoon. The bus-stop of the respective route are fixed by the transport department.
                                Student availing bus facility are expected to continue for the whole session. If anyone needs the bus facility in between the session, he has to pay the entire fees for the whole session. No withdrawal from the bus facility in between the session. If so, the entire bus fee for the session will be charged.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Medical Facilities</h3>
                            <p style={{ textAlign: "justify" }}>A Doctor, with experience and qualification has been appointed as the Medical Officer visits the hostel regularly. Medical checkup of students is done and a record is maintained. Parents/Local guardians are informed if the doctor finds that a child needs special medical attention. Well-equipped First-Aid box is available in the office round the clock and students sustaining minor injuries in course of training, sports or games are attended to immediately. One vehicle of the school remains on standby duty to face any emergency.</p>
                            <p style={{ textAlign: "justify" }}></p>

                        </div>
                        <div className="col-lg-6 col-md-8 offset-lg-0 offset-xl-0 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/residents.jpg" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row my-5">
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block left-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Science Lab.jpeg" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-xl-5 mt-lg-5 mt-md-2 mt-sm-5 mt-5">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Science Lab</h3>
                            <p style={{ textAlign: "justify" }}>Additionally, it features a state-of-the-art Science lab for students in Classes V–XII. Senior students get access to the lab as well. The Science lab has been expanded, and the senior secondary students now have their own Science lab.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Library</h3>
                            <p style={{ textAlign: "justify" }}>School libraries are similar to public libraries in that they contain books, films, recorded sound, periodicals, realia, and digital media. These items are not only for the education, enjoyment, and entertainment of all members of the school community, but also to enhance and expand the school curriculum</p>
                            <p style={{ textAlign: "justify" }}></p>

                        </div>
                        <div className="col-lg-6 col-md-8 offset-lg-0 offset-xl-0 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Library.jpg" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="target">
                <div className="container">
                    <div className="sec-title text-center bg-fill mb-3">
                        <h2 className="text-white">Our Facility</h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="target-block text-white p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-users fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Laboratories</h4>
                                <p style={{ textAlign: "justify" }}>The school has well equipped laboratories for Physics, Chemistry, Biology and Mathematics</p>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white  p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-mortar-board fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Sports</h4>
                                <p style={{ textAlign: "justify" }}>A good combination of indoor as well as outdoor games in the school ensures a balanced development of the students. Sports tournaments are held from time to time.</p>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white p-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-book fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Smart Class</h4>
                                <p style={{ textAlign: "justify" }}>Keeping latest development in the education field in mind, school has introduced smart class Facility. It will help the students to learn through interaction method.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeReviews />

        </>
    )
}

export default Facility