import React from 'react'

const Activities = () => {
    return (
        <>

            <section className="inner-banner">
                <h1 className="font-weight-bold text-center">Activities</h1>
            </section>


            <section className="achievement">
                <div className="container">
                    <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                        <h2>Our Activities</h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Sports and Athletics</h3>
                            <p style={{ textAlign: "justify" }}>We offer a comprehensive sports program that encourages students to develop their physical abilities, sportsmanship, and teamwork. Our well-equipped sports facilities and experienced coaches enable students to participate in various sports such as football, basketball, cricket, swimming, and athletics.</p>
                         


                        </div>
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Athelicts-03.JPG" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block left-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/arts and culture.JPG" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-xl-5 mt-lg-5 mt-md-2 mt-sm-5 mt-5">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Arts and Culture</h3>
                            <p style={{ textAlign: "justify" }}>The arts play a vital role in nurturing creativity and self-expression. Our school provides a vibrant platform for students to explore their artistic talents through activities such as music, dance, drama, painting, and sculpture. We organize regular performances and exhibitions to showcase their artistic achievements.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Clubs and Societies</h3>
                            <p style={{ textAlign: "justify" }}>We offer a wide range of clubs and societies that cater to diverse interests and passions. Students have the opportunity to join clubs such as robotics, coding, photography, debating, environmental conservation, and community service. These clubs provide a platform for students to pursue their interests, develop new skills, and engage in meaningful projects.</p>

                        </div>
                        <div className="col-lg-6 col-md-8 offset-lg-0 offset-xl-0 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/club-society-2.png" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="row my-5">
                        <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                            <div className="img-block left-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/FieldTrips.JPG" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-xl-5 mt-lg-5 mt-md-2 mt-sm-5 mt-5">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Field Trips and Excursions</h3>
                            <p style={{ textAlign: "justify" }}>We believe that experiential learning is essential for a holistic education. Our school organizes field trips and excursions to various educational and recreational destinations. These outings provide students with real-world experiences, enhance their knowledge, and foster a sense of adventure and exploration.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Competitions and Events</h3>
                            <p style={{ textAlign: "justify" }}>We encourage our students to participate in various inter-school and intra-school competitions and events. These include academic quizzes, science fairs, art competitions, cultural festivals, and sports tournaments. Such events promote healthy competition, teamwork, and a spirit of achievement among our students.</p>

                        </div>
                        <div className="col-lg-6 col-md-8 offset-lg-0 offset-xl-0 offset-md-2">
                            <div className="img-block">
                                <div className="border-line" />
                                <div className="cust_img">
                                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Competitions and Events.jpg" alt="Achievement" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Activities