import React from 'react'
import { Link } from "react-router-dom";


const HomeBlog = () => {
    return (
        <>

            <section className="blog">
                <div className="container">
                    <div className="sec-title text-center mb-3">
                        <span className="title">BLOG &amp; NEWS</span>
                        <h2>We keep you always updated with <br /> our fresh blog posts </h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/school/events/114.JPG" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <p className="mb-1">01th January,2024</p>
                                    <h5 className="fw-bold">How can students enhance their communication skills effectively?</h5>
                                    
                                   
                                    <Link to="/Blog" className="btn color-orange read-more mt-3">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/school/events/115.JPG" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <p className="mb-1">10th March,2024 </p>
                                    <h5 className="fw-bold">Importance of physical education for young children.</h5>
                                  
                                    <Link to="/Blog" className="btn color-orange read-more mt-3">Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/school/events/116.JPG" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <p className="mb-1">10th November,2023 </p>
                                    <h5 className="fw-bold">A Vibrant Diwali Celebration at Sudarshan Public Hr.Sec. School.</h5>
                                    <Link to="/Blog" className="btn color-orange read-more mt-3">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeBlog