import React from 'react'
import MissionVision from '../home/MissionVision'
import Opportunities from '../home/Opportunities'

const DirectorMessage = () => {
  return (
    <>
      <div>
        <section className="inner-banner">
          <h1 className="fw-bold text-center">Director Message</h1>
        </section>
        <section className="achievement">
          <div className="container">
            <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
              <h2>Director Message</h2>
              <div className="divider">
                <span className="fa fa-mortar-board" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Dr. Rajkumar kushwaha</h3>
                <h6 className="fw-bold mb-3" style={{marginLeft:"160px"}}>(Director Message)</h6>
                
                <p style={{textAlign:"justify"}}>As the Chancellor of Sudarshan Public Hr.Sec. School, I extend a warm welcome to all aspiring students and their parents. Our institution is dedicated to nurturing young minds and providing a ladder to success through student-centric education. Our esteemed faculty, a blend of experience and skill, ensures quality learning aligned with evolving economic trends. We aim to mold resilient individuals with strong academic foundations, equipped to navigate life's challenges. Offering diverse subjects and advanced technologies, we believe knowledge is paramount. Our mission is to empower society with competent professionals.</p>
                <p style={{textAlign:"justify"}}>As we shape tomorrow's leaders, I invite students and parents to join us on this transformative journey. Together, we'll forge a brighter future, and our pursuit of excellence will propel Sudarshan Public Hr.Sec. School to global recognition. Remember, with Sudarshan Public Hr.Sec. School, the possibilities are limitless.</p>
              </div>
              <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                <div className="img-block">
                  <div className="border-line" />
                  <div className="cust_img">
                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/school/events/Director.jpg" alt="Achievement" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <MissionVision />
      <Opportunities />


    </>
  )
}

export default DirectorMessage