import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>

      <footer className="theme-blue">
        <div className="container">
          <div className="footer-top border-bottom pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6" data-aos="fade-in" data-aos-duration={1050}>
                <Link to="index.html">
                  <img src="assets/images/school/events/logo-1.png" className="img-fluid mb-3" alt="Edusquad" style={{ height: "190px" }} />
                </Link>
              
                <ul className="social-icon">
                  <li>
                    <Link to="/Contact">
                      <i className="fa fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/sudarshanpublicschool?utm_source=qr&igsh=MWFzMHNtNzh4cWNmOA%3D%3D" target="_blank">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <i className="fa fa-twitter" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/Contact">
                      <i className="fa fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <i className="fa fa-skype" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <i className="fa fa-linkedin" />
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-2 col-md-6" data-aos="fade-in" data-aos-duration={550}>
                <h5 className="fw-bold mb-3">Quick Links</h5>
                <ul>
                  <li>
                    <Link to="index.html">
                      <i className="fa fa-angle-double-right me-2" />Home </Link>
                  </li>
                  <li>
                    <Link to="/Facility">
                      <i className="fa fa-angle-double-right me-2" />Facility </Link>
                  </li>
                  <li>
                    <Link to="/Activities">
                      <i className="fa fa-angle-double-right me-2" />Activities </Link>
                  </li>
                  <li>
                    <Link to="/Admission">
                      <i className="fa fa-angle-double-right me-2" />Admission </Link>
                  </li>
                  <li>
                    <Link to="/TopResult">
                      <i className="fa fa-angle-double-right me-2" />Top Result </Link>
                  </li>
                  <li>
                    <Link to="/Gallery">
                      <i className="fa fa-angle-double-right me-2" />Gallery </Link>
                  </li>
                  <li>
                    <Link to="/Contact">
                      <i className="fa fa-angle-double-right me-2" />Contact </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 mb-md-5 mb-4" data-aos="fade-in" data-aos-duration={1050}>
                <h5 className="fw-bold mb-3">Recent News</h5>
                <div className="row">
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/200w.GIF" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/giphy.GIF" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/make.GIF" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_4.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_5.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_6.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ps-lg-5  mb-md-5" data-aos="fade-in" data-aos-duration={1050}>
                <h5 className="fw-bold mb-3">Contact Us</h5>
                <ul className="address-icon">
                  <li className="mb-3">
                    <i className="fa fa-map-marker me-3 color-orange" /> Acharya Vinoba Bhave Ward No.-15 Hanuman Nagar, Satna, Madhya Pradesh 485001
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-phone me-3 color-orange" /> <a href="tel:+91  9893806170">+91 9893806170</a><br />
                    <i className="fa fa-phone me-3 color-orange" /><a href="tel:+91  7999108631">+91 7999108631</a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-envelope color-orange me-3" />sudarshan312317@gmail.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom-footer py-2">
            <p className="mb-0" style={{textAlign:"center"}}>2024 © Sudarshan Public Hr.Sec. School 2024 | Designed by <a href='tel:+91  8982471422'>Web solution & digital marketing</a></p>
          </div>
        </div>
      </footer>


      <WhatsappIcon />

    </>
  )
}

export default Footer