import React from 'react'
import MissionVision from '../home/MissionVision'
import OurTeam from '../home/OurTeam'
import HomeReviews from '../home/HomeReviews'
import HomeGallery from '../home/HomeGallery'
import HomeBlog from '../home/HomeBlog'

const AboutUs = () => {
  return (
    <>
      <div>
        <section className="inner-banner">
          <h1 className="fw-bold text-center">About Us</h1>
        </section>
        <section className="aboutus">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h1 className="color-orange fw-bold about-title" data-aos="fade-up" data-aos-duration={1000}>20+</h1>
                <h5 className="fw-bold text-uppercase mb-3" data-aos="fade-up" data-aos-duration={1000}>YEARS OF EXPERIANCE</h5>
                <p style={{ textAlign: "justify" }}>Sudarshan Public Hr.Sec. School is one of the best health and safety course providers in India. is approved by the government of India.</p>
                <p style={{ textAlign: "justify" }}>Sudarshan Public Hr.Sec. School was established in 2004, and over the duration of its more than 20 years of existence, it has made impressive achievements and sustained an integrated development of all services for the needs of the students. We effectively shaped the careers of thousands of students in the health and safety industry. And we sustained our legacy of quality assurance.</p>
                {/* <p>We are here to support you and make sure you have fun while learning at the Institute. We can ensure you that you are in excellent hands and are prepared for success.</p> */}
                {/* <p style={{textAlign:"justify"}}>Someone has truly said that education is soul crafting. That is what we at Sudarshan Public Hr.Sec. School aim for. We not only believe in the intellectual development but also stress upon growth from within. Aiming at holistic development, we prepare children to take the world in their stride and mould them into being successful while simultaneously imparting values that make them good humans in the society. With world-class education, extra-curricular activities and par excellence grooming we show them how to pave the way towards their dreams.</p> */}
                <p style={{ textAlign: "justify" }}>At Sudarshan Public Hr.Sec. School, teachers are committed to the constant development and growth of the students in order to teach them to harness their potential and achieve anything they take up in the future, with flying colours. </p>
                <p style={{ textAlign: "justify" }}>A strong alumni network and their expertise is what we believe will help present students of the school. We also aim to make the alumni network stronger and get them to connect amongst themselves so as to facilitate sharing of knowledge and expertise in different fields and industries. </p>

              </div>
              <div className="col-lg-6 col-md-8 offset-md-2 offset-lg-0">
                <div className="about-img">
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                    <div className="border-line" />
                    <img src="assets/images/school/events/about_1.jpg" className="img-fluid" alt="About" />
                  </div>
                  <div className="img_2 d-flex">
                    <h5 className="mb-0 text-white">Our Story</h5>
                    <a href="https://www.youtube.com/@sudarshanpublicschool5741/videos" className="video-btn text-white">
                      <i className="fa fa-play-circle fa-3x" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <MissionVision />
        <section className="counter-section abt-counter">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title" data-aos="fade-up" data-aos-duration={1000}>
                  <span className="title">About Us</span>
                  <h2>Welcome To Sudarshan Public Hr.sec. School</h2>
                  <div className="divider">
                    <span className="fa fa-mortar-board" />
                  </div>
                </div>
                <p className="p-17" style={{ textAlign: "justify" }}>A sprawling, lush green 01 acres campus. Progressive and modern teaching methods.English Language Lab.Well-stocked and updated Library.Well equipped Science Labs.</p>
                <p className="p-17" style={{ textAlign: "justify" }}>With the rising awareness and possibilities, we believe that technology will help the students achieve new heights. Experts at MPS also believe in the positive influence it has in the classrooms along with ease of back-end office operations. </p>
                <p className="p-17" style={{ textAlign: "justify" }}>Quick access to information ‘anytime, anywhere’ is the defining mantra for educational digital learning, at Sudarshan Public Hr.Sec. School.</p>
              </div>
              <div className="col-lg-6">
                <div className="counter-box">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-md-5">
                      <div className="counter aos-init aos-animate mb-5" data-aos="fade-up" data-aos-duration={550}>
                        <div className="border-line" />
                        <div className="counter-img">
                          <img src="assets/images/icons/teacher.png" className="img-fluid" alt="Teacher" />
                        </div>
                        <h2 className="timer count-title count-number" data-to={55} data-speed={1500}>55+</h2>
                        <h5 className="count-text mt-2">Teachers</h5>
                      </div>
                      <div className="counter aos-init aos-animate" data-aos="fade-up" data-aos-duration={550}>
                        <div className="border-line" />
                        <div className="counter-img">
                          <img src="assets/images/icons/classroom.png" className="img-fluid" alt="Classroom" />
                        </div>
                        <h2 className="timer count-title count-number" data-to={24} data-speed={1500}>24+</h2>
                        <h5 className="count-text mt-2">Classroom</h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mt-lg-5 mt-lg-5 mt-md-5">
                      <div className="counter aos-init aos-animate" data-aos="fade-up" data-aos-duration={550}>
                        <div className="border-line" />
                        <div className="counter-img">
                          <img src="assets/images/icons/career.png" className="img-fluid" alt="Career" />
                        </div>
                        <h2 className="timer count-title count-number" data-to={955} data-speed={1500}>955+</h2>
                        <h5 className="count-text mt-2">Students</h5>
                      </div>
                      <div className="counter margin-bottom aos-init aos-animate margin-bottom" data-aos="fade-up" data-aos-duration={550}>
                        <div className="border-line" />
                        <div className="counter-img">
                          <img src="assets/images/icons/activity.png" className="img-fluid" alt="Activities" />
                        </div>
                        <h2 className="timer count-title count-number" data-to={20} data-speed={1500}>20+</h2>
                        <h5 className="count-text mt-2">Award</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <OurTeam />
      <HomeReviews />
      <HomeGallery />
      <HomeBlog />

    </>
  )
}

export default AboutUs