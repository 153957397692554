import React from 'react'

const PrincipalsMessage = () => {
  return (
    <>
      <div>
        <section className="inner-banner">
          <h1 className="fw-bold text-center">Achievement</h1>
        </section>
        <section className="achievement">
          <div className="container">
            <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
              <span className="title">Our Achievement</span>
              <h2>Happiness Of Great Achievement</h2>
              <div className="divider">
                <span className="fa fa-mortar-board" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Achievement 1</h3>
                <h6 className="fw-bold mb-3">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.”</h6>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
                  scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore.</p>
              </div>
              <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                <div className="img-block">
                  <div className="border-line" />
                  <div className="cust_img">
                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/gallery_1.jpg" alt="Achievement" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-lg-6 offset-lg-0 offset-xl-0 col-md-8 offset-md-2">
                <div className="img-block left-block">
                  <div className="border-line" />
                  <div className="cust_img">
                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/gallery_2.jpg" alt="Achievement" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mt-xl-5 mt-lg-5 mt-md-2 mt-sm-5 mt-5">
                <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Achievement 2</h3>
                <h6 className="fw-bold mb-3">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.”</h6>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
                  scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h3 className="fw-bold color-orange" data-aos="fade-up" data-aos-duration={1000}>Achievement 3</h3>
                <h6 className="fw-bold mb-3">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.”</h6>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
                  scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>
                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web
                  designs.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore.</p>
              </div>
              <div className="col-lg-6 col-md-8 offset-lg-0 offset-xl-0 offset-md-2">
                <div className="img-block">
                  <div className="border-line" />
                  <div className="cust_img">
                    <img className="img-fluid" data-aos="zoom-in" data-aos-duration={1000} src="assets/images/gallery_3.jpg" alt="Achievement" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


    </>
  )
}

export default PrincipalsMessage