import React from 'react'
import Footer from './layout/Footer'
import { Outlet } from 'react-router-dom';
import Hadear1 from './layout/Hadear1';

const MainLayOut = () => {
  return (
    <>
      {/* <Hadear /> */}
      <Hadear1 />
      <Outlet />
      <Footer />
    </>
  );
}

export default MainLayOut