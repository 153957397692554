import React from 'react'
import { Link } from "react-router-dom";


const HomeAbout = () => {
    return (
        <>

            <section className="online-course">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="sec-title">
                                {/* <span className="title">About US</span> */}
                                <h2>About US</h2>
                                <div className="divider">
                                    <span className="fa fa-mortar-board" />
                                </div>
                            </div>
                            <p className="p-17" style={{textAlign:"justify"}}>Sudarshan Public Hr.Sec. School is one of the best health and safety course providers in India. is approved by the government of India.</p>
                            <p className="p-17" style={{textAlign:"justify"}}>Sudarshan Public Hr.Sec. School was established in 2004, and over the duration of its more than 20 years of existence, it has made impressive achievements and sustained an integrated development of all services for the needs of the students. We effectively shaped the careers of thousands of students in the health and safety industry. And we sustained our legacy of quality assurance.</p>
                            <p className="p-17" style={{textAlign:"justify"}}>We are here to support you and make sure you have fun while learning at the Institute. We can ensure you that you are in excellent hands and are prepared for success.</p>
                            <p className="p-17" style={{textAlign:"justify"}}>Someone has truly said that education is soul crafting. That is what we at Sudarshan Public Hr.Sec. School aim for. We not only believe in the intellectual development but also stress upon growth from within. Aiming at holistic development, we prepare children to take the world in their stride and mould them into being successful while simultaneously imparting values that make them good humans in the society. With world-class education, extra-curricular activities and par excellence grooming we show them how to pave the way towards their dreams. </p>
                            <Link to="/AboutUs">
                            <button type="button" className="btn theme-orange theme-btn my-2 join-us">Read More</button></Link>
                        </div>
                        <div className="col-lg-6">
                            <div className="course-block  d-flex justify-content-between bg-light pa-2 mx-5 my-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/promotion.png" className="img-fluid m-auto" alt="Key Of Success" />
                                <div className="course-text ps-5">
                                    <h4 >fully CCTV Campus</h4>
                                    <p style={{textAlign:"justify"}}>We use CCTV security in classroom to see activity of student with school security camera footage, the administrators can keep track of student’s activity.</p>
                                </div>
                            </div>
                            <div className="course-block  d-flex justify-content-between theme-blue pa-2 mx-5 my-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/online-class.png" className="img-fluid m-auto" alt="Our Philosophy" />
                                <div className="course-text ps-5">
                                    <h4 style={{fontSize:"17px"}}>20-25 Student in each Classroom</h4>
                                    <p style={{textAlign:"justify"}}>Having fewer students means that each one can get the attention they need from their teacher. They are also encouraged to take part in discussions and driven to express their opinions.</p>
                                </div>
                            </div>
                            <div className="course-block  d-flex justify-content-between theme-orange pa-2 mx-5 mt-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/feminism.png" className="img-fluid m-auto" alt="Our Principle" />
                                <div className="course-text ps-5">
                                    <h4>Hygienic lunch facility</h4>
                                    <p>Food hygiene is one of the biggest health concerns for local authorities, as is, of course, the health and safety of children at school.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeAbout